import {Component} from '@angular/core';
import {Apollo} from "apollo-angular";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'triton-fe';

  constructor(apollo: Apollo) {
  }
}
