import {Component} from '@angular/core';
import {faBell, faSearch, faUserAlt} from '@fortawesome/free-solid-svg-icons';
import {NgEventBus} from "ng-event-bus";

export class TitleEvent {
  pageTitle: string = 'Dashboard'
  navBar?: { name: string, url?: string, key?: any }[]
  backUrl?: string
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  title: TitleEvent = {pageTitle: '', navBar: []}

  protected readonly faUserAlt = faUserAlt;
  protected readonly faBell = faBell;
  protected readonly faSearch = faSearch;

  constructor(private eventBus: NgEventBus) {
    eventBus.on("page:title").subscribe(x => this.title = x.data as TitleEvent)
  }
}
