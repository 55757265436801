import {Injectable} from '@angular/core';
import {JwtHelperService} from "@auth0/angular-jwt";
import {HttpClient} from "@angular/common/http";
import {KeycloakEvent, KeycloakEventType, KeycloakService} from "keycloak-angular";
import {map, Observable} from "rxjs";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

const helper = new JwtHelperService();


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private keycloak: KeycloakService,
              private http: HttpClient) {
    keycloak.keycloakEvents$.subscribe({
      next(event: KeycloakEvent) {
        if (event.type == KeycloakEventType.OnTokenExpired) {
          keycloak.updateToken(60);
        }
      }
    });
  }

  getJwtToken(): Observable<string> {
    return fromPromise(this.keycloak.getToken())
  }


  getUsername(): Observable<string> {
    return this.getJwtToken().pipe(
      map(jwt => helper.decodeToken<any>(jwt)),
      map(jwt => jwt['name'])
    )
  }


  logout() {

    // this.http.post("http://localhost:8085/realms/triton/protocol/openid-connect/logout?client_id=account", null, {}).pipe().subscribe(x => this.clearToken())
    this.keycloak.logout(window.origin)
  }
}
