import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {split} from "@apollo/client";
import {getMainDefinition} from "@apollo/client/utilities";
import {ApolloClientOptions, InMemoryCache} from "@apollo/client/core";
import {WebSocketLink} from "@apollo/client/link/ws";
import {HttpLink} from "apollo-angular/http";
import {AuthService} from "./service/auth.service";
import {environment} from "../environments/environment";
import {APOLLO_FLAGS, APOLLO_OPTIONS, ApolloModule} from "apollo-angular";
import {LayoutComponent} from './layout/layout.component';
import {HeaderComponent} from './layout/header/header.component';
import {MenuComponent} from './layout/menu/menu.component';
import {MatIconModule} from "@angular/material/icon";
import {IconsModule} from "./icons/icons.module";
import {AvatarComponent} from './layout/avatar/avatar.component';
import {MatMenuModule} from '@angular/material/menu';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatSuffix} from "@angular/material/form-field";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {TitleCasePipe, UpperCasePipe} from "@angular/common";
import {ToastrModule} from "ngx-toastr";
import {KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService} from "keycloak-angular";
import {NgxEchartsModule} from "ngx-echarts";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgEventBus} from "ng-event-bus";
import {LogoComponent} from "./layout/logo/logo.component";
import {ComponentsModule} from "./components/components.module";
import {NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {NgbDateCustomParserFormatter} from "./components/custom-date-parser";

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: 'triton',
        clientId: 'account'
      },
      initOptions: {
        onLoad: 'check-sso',
        adapter: 'default',
        redirectUri: window.location + '/dashboard',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    });
}


export function createApollo(httpLink: HttpLink, authService: AuthService): ApolloClientOptions<any> {
  const http = httpLink.create({
    uri: environment.graphql_endpoint,
    headers: new HttpHeaders().set(
      "X-Hasura-Role", "admin"
    )
  })
  const ws: WebSocketLink = new WebSocketLink({
    uri: environment.ws_endpoint,
    options: {
      reconnect: true,
      connectionParams: {
        // authToken: 'Bearer ' + authService.getJwtToken(),
        // headers: {
        //   'Authorization': `Bearer ${authService.getJwtToken()}`,
        //   // "X-Hasura-Role": "admin"
        // }

      }
    }
  });


  const link = split(
    ({query}) => {
      const data = getMainDefinition(query);
      return (
        data.kind === 'OperationDefinition' && data.operation === 'subscription'
      );
    },
    ws,
    http
  )

  return {
    cache: new InMemoryCache(),
    link: link
  }
}


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    LogoComponent,
    MenuComponent,
    AvatarComponent,

  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        KeycloakAngularModule,
        AppRoutingModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        IconsModule,
        MatIconModule,
        MatMenuModule,
        MatSuffix,
        ApolloModule,
        MatNativeDateModule,
        FontAwesomeModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        ComponentsModule,
        NgbModule,
        MatIconButton,
        MatTooltip
    ],
  providers: [
    AuthService,
    TitleCasePipe,
    UpperCasePipe,
    MatNativeDateModule,
    // {provide: FormioAppConfig, useValue: AppConfig},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    },
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: true // enable it here
      }
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    NgEventBus,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},


],
  bootstrap: [AppComponent]
})
export class AppModule {
}
