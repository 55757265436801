<div class="flex flex-col space-y-3 manu-container mt-6">
  @for (item of menuItems; track item.id) {
    <a (click)="setMenu(item)"
       [routerLink]="item.url ? [item.url] : null"
       [attr.data-active]="item.selected"
       [attr.data-expandable]="!!item.children"
       [attr.data-state]="!!item.children && item.open ? 'open':'not-open'"
       class="ml-4 cursor-pointer group grid grid-cols-10 gap-2  ease-soft-in-out text-sm py-2.7  items-center whitespace-nowrap rounded-lg hover:bg-white px-2   py-2 font-semibold text-slate-700
                 data-[active=true]:bg-white data-[active=true]:shadow">

      <div class="col-span-2 stroke-none shadow-lg text-slate-700 bg-gradient-to-r  flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center fill-current p-2.5 text-center
                group-data-[active=true]:from-cyan-500  group-data-[active=true]:to-blue-500">
        <fa-icon [icon]="item.icon" class="group-data-[active=true]:text-gray-50  "></fa-icon>

      </div>

      <span class="col-span-7 ml-1 duration-300 opacity-100 pointer-events-none ease-in-out text-slate-700">
        {{ item.name }}
      </span>

      <div class=" transition-all origin-center ease-in-out group-data-[state=open]:rotate-180">
        <fa-icon *ngIf="!!item.children" [icon]="faAngleDown"></fa-icon>
      </div>
    </a>
    <div [attr.data-state]="!!item.children && item.open ? 'open':'not-open'"
         class=" h-auto max-h-0 overflow-hidden transition-all duration-200 ease-in-out data-[state=open]:max-h-60 text-sm">
      @for (child of item.children; track child.id) {
        <div class="px-6 py-2 group grid grid-cols-10 gap-2 items-center  cursor-pointer  data-[sub-active=true]:text-slate-800"
             (click)="setMenu(child)"
             [routerLink]="child.url ? [child.url] : null"
             [attr.data-sub-active]="child.selected">
          <div class="justify-self-center col-span-2 rounded-full size-1 bg-slate-500 group-data-[sub-active=true]:size-2 origin-center transition-all duration-200 ease-in-out">

          </div>
          <div class="col-span-8 group-data-[sub-active=true]:font-bold">
            {{ child.name|titlecase }}
          </div>
        </div>
      }
    </div>

  }

  <!--  <div class="flex space-x-2 items-center menu-item hover:" [routerLink]="['/','dashboard']">-->
  <!--    <div><img src="assets/images/anagrafica.svg" class="min-w-[24px]"></div>-->
  <!--    <div>Dashboard</div>-->
  <!--  </div>-->
  <!--  <div class="flex space-x-2 items-center menu-item" [routerLink]="['/','boats']">-->
  <!--    <div><img src="assets/images/anagrafica.svg" class="min-w-[24px]"></div>-->
  <!--    <div>Anagrafica</div>-->
  <!--  </div>-->
  <!--  <div class="flex space-x-2 items-center menu-item" [routerLink]="['/','port']">-->
  <!--    <div><img src="assets/images/calendar.svg" class="min-w-[24px]"></div>-->
  <!--    <div>Porti</div>-->
  <!--  </div>-->
  <!--  <div class="flex space-x-2 items-center menu-item">-->
  <!--    <div><img src="assets/images/file.svg" class="min-w-[24px]"></div>-->
  <!--    <div>Prenotazioni</div>-->
  <!--  </div>-->
</div>
